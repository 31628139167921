import React from "react";
import BlogShortcut from "sections/Blog/BlogShortcut";
import Lawyer from "sections/Lawyer/Lawyer";
import ServicesScope from "sections/ServicesScope/ServicesScope";
import MainTemplate from "templates/MainTemplate";
import Salary from "sections/Salary/Salary";
import Contact from "sections/Contact/Contact";
import Quote from "sections/Quote/Quote";
import { Helmet } from "react-helmet";

const IndexPage = () => (
  <MainTemplate>
    <Helmet>
      <title>Adwokat Adrianna Firlej-Koniecka</title>
    </Helmet>
    <Quote />
    <Lawyer />
    <ServicesScope />
    <BlogShortcut />
    <Salary />
    <Contact />
  </MainTemplate>
);

export default IndexPage;
