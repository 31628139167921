import React from "react";
import styled from "styled-components";
import ArrowLeft from "assets/icons/arrow-left.inline.svg";
import ArrowRight from "assets/icons/arrow-right.inline.svg";
import { Link } from "gatsby";

export const StyledButton = styled(Link)`
  padding: 5px 10px;
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.colors.gold};
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  svg {
    width: 20px;
    margin-right: 5px;
    fill: ${({ theme }) => theme.colors.gold};
  }
`;

const ButtonLink = ({ label, arrowPosition = "", to, className }) => (
  <StyledButton to={to} className={className}>
    {arrowPosition === "left" ? <ArrowLeft /> : null}
    {label}
    {arrowPosition === "right" ? <ArrowRight /> : null}
  </StyledButton>
);

export default ButtonLink;
