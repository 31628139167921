import React from "react";
import styled from "styled-components";
import picture from "assets/pictures/sit.jpg";
import contactCard from "assets/pictures/contactCard.jpg";
import CheckIcon from "assets/icons/check.inline.svg";
import SectionName from "components/SectionName/SectionName";
import ContactItems from "sections/TopContact/ContactItems";
import ContactForm from "./ContactForm";

const Background = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Note = styled.div`
width: 90%;
  max-width: 1100px;
  margin: 80px auto;
  p {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: ${({ theme }) => theme.fontSize.ml};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: 28px;
    margin: 20px 0;
  }
  @media screen and (max-width: 900px) {
    display: flex;
    flex-wrap: wrap-reverse;
    font-size: ${({ theme }) => theme.fontSize.ml};
    width: 85%;
    p {
      font-size: ${({ theme }) => theme.fontSize.ml};
      font-weight: ${({ theme }) => theme.fontWeight.regular};
    }
  }`

const Wrapper = styled.div`
  max-width: 1000px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 40px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  p {
    margin: 30px 0 18px;
    line-height: 26px;
    color: ${({ theme }) => theme.colors.mediumGrey};
    font-size: ${({ theme }) => theme.fontSize.m};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
  @media screen and (max-width: 1200px) {
    padding-top: 40px;
  }
  @media screen and (max-width: 700px) {
    margin-top: 20px;
    text-align: justify;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 40px;
  }
`;

const PictureWrapper = styled.div`
  margin-top: 15px;
  img {
    width: 100%;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  svg {
    color: ${({ theme }) => theme.colors.gold};
    width: 20px;
    margin-right: 10px;
  }
  p {
    padding: 5px 0;
    margin: 6px 0;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: ${({ theme }) => theme.fontSize.m};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
  a {
    text-decoration: none;
    padding: 5px 0;
    margin: 6px 0;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: ${({ theme }) => theme.fontSize.m};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const OpenHoursLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Contact = () => (
  <Background id="kontakt">
    <SectionName label="Kontakt" />
    <Note>
      <p>
        W sprawach pilnych, które wymagają natychmiastowej reakcji, w szczególności w przypadku zatrzymania, prosimy o kontakt telefoniczny pod nr 667 610 488. W sytuacjach wyjątkowych, możliwe jest umówienie spotkania po godzinach pracy Kancelarii, a także w dni wolne od pracy, również poza siedzibą Kancelarii. Możliwy jest również dojazd do klienta (za dopłatą). 
      </p>
    </Note>
    <Wrapper>
      <PictureWrapper className="adwokat">
        {/* <img src={picture} alt={picture} /> */}
        <ContactForm />
      </PictureWrapper>
      <Container>
        <InnerWrapper>
          <OpenHoursLine>
            <CheckIcon />
            <p>Godziny otwarcia 8-15 | <b>Wejście od ul. Solnej</b></p>
          </OpenHoursLine>
          <ContactItems isMobile />
        </InnerWrapper>
        <PictureWrapper>
          <img src={contactCard} alt={contactCard} />
        </PictureWrapper>
        <div>
          <iframe
            width="100%"
            height="300"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://www.openstreetmap.org/export/embed.html?bbox=22.55379974842072%2C51.24408487028359%2C22.557340264320377%2C51.24547008508306&amp;layer=mapnik&amp;marker=51.24477664337415%2C22.555570006370544"
            style={{ border: "0" }}
            title="map"
          />
        </div>
      </Container>
    </Wrapper>
  </Background>
);
export default Contact;
