import React from "react";
import styled from "styled-components";
import ButtonLink from "components/Button/ButtonLink";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ArrowRight from "../../assets/icons/arrow-right.inline.svg";

export const Card = styled(AnchorLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  &:last-of-type {
    grid-column-start: 2;
    grid-row-start: 3;
    @media screen and (max-width: 900px) {
      grid-column-start: unset;
      grid-row-start: unset;
    }
  }
  &:visited {
    color: inherit;
  }
  > div {
    width: 100%;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.darkGrey};
    box-shadow: 0px 0px 25px 5px rgba(51, 51, 51, 0.35);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    &:hover {
      transform: scale(1.05);
    }
    > svg {
      color: ${({ theme }) => theme.colors.gold};
      width: 50px;
    }
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.black}
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  padding: 0;
  margin: 8px;
  text-align: center;
`;

export const ReadMore = styled.span`
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGrey};
  position: absolute;
  right: 10px;
  bottom: 10px;
  transition: color 0.1s;
  &:hover {
    color: ${({ theme }) => theme.colors.gold};
    svg {
      fill: ${({ theme }) => theme.colors.gold};
    }
  }
  svg {
    width: 20px;
    margin-right: 5px;
    fill: ${({ theme }) => theme.colors.darkGrey};
    transition: fill 0.1s;
  }
`;

const ServicesCard = ({ icon, title, href, onAnchorLinkClick }) => (
  <Card to={href} onAnchorLinkClick={onAnchorLinkClick}>
    <div>
      {icon}
      <Title>{title}</Title>
      <ReadMore>
        Czytaj więcej
        <ArrowRight />
      </ReadMore>
    </div>
  </Card>
);

export default ServicesCard;
