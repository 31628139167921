import React from "react";
import styled from "styled-components";
import picture from "assets/pictures/adwFirlej.jpg";
import SectionName from "components/SectionName/SectionName";

const Background = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Wrapper = styled.div`
  max-width: 1000px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 40px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  p {
    margin: 30px 0 18px;
    line-height: 26px;
    color: ${({ theme }) => theme.colors.mediumGrey};
    font-size: ${({ theme }) => theme.fontSize.m};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
  @media screen and (max-width: 1200px) {
    padding-top: 40px;
  }
  @media screen and (max-width: 700px) {
    margin-top: 20px;
    text-align: justify;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr fr;
    grid-gap: 40px;
  }
`;

const PictureWrapper = styled.div`
  img {
    width: 100%;
  }
`;

const Salary = () => (
  <Background id="wynagrodzenie">
    <SectionName label="Wynagrodzenie" />
    <Wrapper>
      <div>
        <div>
          <p>
            Świadczenie pomocy prawnej przez Kancelarię następuje na podstawie
            zleceń lub umowy o stałą obsługę prawną. Porady prawne udzielane są
            w siedzibie Kancelarii przy ul. Orlej 3/1 lok. 2 w Lublinie lub na
            odległość (drogą telefoniczną lub on-line, po wcześniejszym
            uzgodnieniu ceny oraz jej zaksięgowaniu na rachunku bankowym
            kancelarii).
          </p>
          <p>
            Mając na uwadze różnorodność spraw, do każdej wyceny podchodzę
            indywidualnie. Cena usług zależy od wielu czynników m.in. rodzaju,
            etapu i stopnia skomplikowania sprawy, nakładu pracy adwokata.
          </p>
          <p>
            Wynagrodzenie jest płatne z góry przed rozpoczęciem przez adwokata
            wykonywanych czynności, a do jego kwoty należy doliczyć obowiązkowy
            podatek VAT.
          </p>
          <p>Istnieje możliwość rozłożenia wynagrodzenia na raty.</p>
          <p>Możliwy jest również dojazd do klienta (za dopłatą)</p>
          <p>Zapraszam do współpracy.</p>
        </div>
      </div>
      <PictureWrapper>
        <img src={picture} alt={picture} />
      </PictureWrapper>
    </Wrapper>
  </Background>
);

export default Salary;
