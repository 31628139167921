import React from "react";
import styled from "styled-components";
import SectionName from "components/SectionName/SectionName";
import { graphql, useStaticQuery } from "gatsby";
import ArticleElement from "components/ArticleElement/ArticleElement";

const Background = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey};
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 1000px;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: ${({ isShort }) =>
    isShort ? "30px 1fr 30px" : "30px 1fr 30px 1fr 30px"};
  grid-gap: 20px;
  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }
  > div {
    &:nth-of-type(1) {
      grid-column: 1/2;
      grid-row: 1/3;
    }
    &:nth-of-type(2) {
      grid-column: 2/3;
      grid-row: 2/4;
    }
    &:nth-of-type(3) {
      grid-column: 1/2;
      grid-row: 3/5;
    }
    &:nth-of-type(4) {
      grid-column: 2/3;
      grid-row: 4/6;
    }
  }
`;

const BlogShortcut = () => {
  const data = useStaticQuery(graphql`
    query LastThree {
      allDatoCmsArticle(
        sort: { order: DESC, fields: meta___publishedAt }
        limit: 3
      ) {
        edges {
          node {
            title
            slug
            excerpt
          }
        }
      }
    }
  `);
  const lastPosts = data.allDatoCmsArticle.edges.map(({ node }) => node);
  if (lastPosts.length === 0) return null;
  return (
    <Background>
      <SectionName label="Blog" />
      <Wrapper isShort={lastPosts.length === 1}>
        {lastPosts.map((post) => (
          <ArticleElement
            key={post.slug}
            title={post.title}
            excerpt={post.excerpt}
            link={`/blog/${post.slug}`}
          />
        ))}
        <ArticleElement
          title="Archiwum"
          excerpt="Wyświetl wszystkie wpisy"
          link="/blog"
        />
      </Wrapper>
    </Background>
  );
};

export default BlogShortcut;
