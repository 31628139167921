import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
`;
export const StyledInput = styled.input`
  outline: none;
  border: unset;
  background-color: ${(props) => props.theme.colors.white};
  padding: 0.75rem 1rem;
  &::placeholder {
    color: ${(props) => props.theme.colors.darkGrey};
  }
  &:focus {
    border-bottom: 2px solid black;
  }
`;
export const Error = styled.span`
  color: #f64f4f;
  align-self: flex-end;
  font-size: 0.9rem;
  position: absolute;
  right: 0;
  bottom: -4px;
`;

const Input = ({ name, value, onChange, type = "text", ...props }) => (
  <Container>
    <StyledInput
      type={type}
      name={name}
      id={name}
      value={value}
      onChange={onChange}
      {...props}
    />
    {!!props.error && <Error>{props.error}</Error>}
  </Container>
);

export default Input;
