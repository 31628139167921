import React from "react";
import picture from "assets/pictures/stand.jpg";
import { Container, Name, StyledText, PictureWrapper } from "./Lawyer.style";

const Lawyer = () => (
  <Container id="adwokat">
    <Name>Adwokat Adrianna Firlej-Koniecka</Name>
    <div>
      <PictureWrapper>
        <img src={picture} alt={picture} />
      </PictureWrapper>
      <div>
        <StyledText>
          Jestem absolwentką Wydziału Prawa i Administracji na Katolickim
          Uniwersytecie Lubelskim w Lublinie. Aplikację Adwokacką odbyłam pod
          patronem adw. Andrzeja Maleszyka, z którego Kancelarią w dalszym ciągu
          współpracuję. W czasie tego etapu kształcenia współpracowałam z
          szeregiem kancelarii adwokackich i radcowskich, zdobywając ogromne
          doświadczenie z zakresu obsługi klienta indywidualnego i biznesowego,
          w tym dotyczące merytorycznej, etycznej i praktycznej specyfiki zawodu
          adwokata.
        </StyledText>
        <StyledText>
          W 2021 r. zdałam egzamin zawodowy, za którego wynik otrzymałam
          wyróżnienie. W tym samym roku również ukończyłam szkolenie mediacyjne
          przy Centrum Mediacji Izby Adwokackiej w Lublinie współorganizowane z
          Centrum Mediacji przy Naczelnej Radzie Adwokackiej i jestem wpisana na listę stałych mediatorów przy Sądzie Okręgowym w Lublinie. Występowałam również w programie
          &quot;Poranek Między Wisłą a Bugiem&quot; w TVP3 Lublin w charakterze
          eksperta.
        </StyledText>
        <StyledText>
          Jestem adwokatem, który interes i dobro klienta stawia ponad wszystko.
          Praca adwokata jest dla mnie nie tylko zawodem, ale także życiową
          pasją, co pozwala ją wykonywać z najwyższym zaangażowaniem. Moim
          klientom gwarantuję szybkie i skuteczne rozwiązania, a także
          rzetelność, profesjonalizm i uczciwość. Możecie Państwo liczyć na
          indywidualne i racjonalne podejście do problemu, a także informację o
          możliwym ryzyku. Dobro i komfort klientów jest dla mnie priorytetem,
          każda sprawa jest traktowana sumiennie i indywidualnie. Moi klienci
          mogą liczyć na dyskrecję i zrozumienie.
        </StyledText>
      </div>
    </div>
  </Container>
);

export default Lawyer;
