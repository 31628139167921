import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 90%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledSectionName = styled.p`
  padding-top: 60px;
  margin-top: 0;
  width: 100%;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  position: relative;
  &:before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 80px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.gold};
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -25px;
    left: 15px;
    width: 80px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.black};
  }
`;

const SectionName = ({ label, className }) => (
  <Wrapper>
    <StyledSectionName className={className}>{label}</StyledSectionName>
  </Wrapper>
);

export default SectionName;
