import styled from "styled-components";

export const Container = styled.div`
  max-width: 1000px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  @media screen and (max-width: 900px) {
    margin-bottom: 20px;
    padding: 0 10px;
    > div {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  @media screen and (max-width: 550px) {
    margin-top: 20px;
  }
`;

export const PictureWrapper = styled.div`
  width: 50%;
  float: right;
  padding-left: 30px;
  img {
    width: 100%;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    float: unset;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Name = styled.h3`
  margin-top: 0;
  margin-bottom: 25px;
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.darkGrey};
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 50%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gold};
  }
  @media screen and (max-width: 500px) {
    font-size: ${({ theme }) => theme.fontSize.l};
  }
`;

export const StyledText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.darkGrey};
  line-height: ${({ theme }) => theme.fontSize.xl};
  text-align: justify;
`;
