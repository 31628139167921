import React from "react";
import styled from "styled-components";
import { Container, Error, StyledInput } from "../Input/Input";

const StyledTextArea = styled(StyledInput).attrs({
  as: "textarea",
})`
  resize: vertical;
  font-family: inherit;
`;

const TextArea = ({ name, value, onChange, ...props }) => (
  <Container>
    <StyledTextArea
      name={name}
      id={name}
      value={value}
      onChange={onChange}
      {...props}
      rows={14}
    />
    {!!props.error && <Error>{props.error}</Error>}
  </Container>
);

export default TextArea;
