import React from "react";
import styled from "styled-components";
import { Formik } from "formik";
import { useForm } from "@formspree/react";
import { validate } from "./helpers";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import TextArea from "../../components/TextArea/TextArea";

const Container = styled.div`
  background-color: #14373a;
  padding: 2rem;
  margin-left: 4rem;
  @media screen and (max-width: 700px) {
    margin-left: unset;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 3rem;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.5rem;
    font-weight: 400;
  }
`;

const initialValues = {
  name: "",
  email: "",
  tel: "",
  message: "",
};
const ContactForm = () => {
  const [state, handleSubmitForm] = useForm("xdobedla");

  return (
    <Container>
      <h1>Formularz kontaktowy</h1>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          handleSubmitForm(values);
          resetForm();
        }}
        validate={validate}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          handleBlur,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <Input
              name="name"
              type="text"
              value={values.name}
              onChange={handleChange}
              placeholder="Imię i nazwisko"
              onBlur={handleBlur}
              error={touched.name && errors.name}
            />
            <Input
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              placeholder="E-mail"
              onBlur={handleBlur}
              error={touched.email && errors.email}
            />
            <Input
              name="tel"
              type="tel"
              value={values.tel}
              onChange={handleChange}
              placeholder="Numer telefonu"
              onBlur={handleBlur}
              error={touched.tel && errors.tel}
            />
            <TextArea
              name="message"
              value={values.message}
              onChange={handleChange}
              placeholder="Wiadomość"
              onBlur={handleBlur}
              error={touched.message && errors.message}
            />
            <Button type="submit" disabled={state.submitting}>
              Wyślij
            </Button>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default ContactForm;
