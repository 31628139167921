import React from "react";
import styled from "styled-components";
import Background from "assets/pictures/background.jpg";
import Icon1 from "assets/icons/scope/Aa.inline.svg";
import Icon2 from "assets/icons/scope/Bb.inline.svg";
import Icon3 from "assets/icons/scope/Cc.inline.svg";
import Icon4 from "assets/icons/scope/Dd.inline.svg";
import Icon5 from "assets/icons/scope/Ee.inline.svg";
import Icon6 from "assets/icons/scope/Ff.inline.svg";
import Icon7 from "assets/icons/scope/Gg.inline.svg";
import ServicesCard from "components/ServicesCard/ServicesCard";

export const BackgroundPic = styled.div`
  position: relative;
  padding-bottom: 120px;
  overflow: hidden;
  &::after {
    content: "";
    background: url(${Background});
    filter: blur(5px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.45;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;

export const Scope = styled.p`
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
  padding: 100px 20px 40px;
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  @media screen and (max-width: 900px) {
    font-size: ${({ theme }) => theme.fontSize.l};
    padding-top: 60px;
    padding-left: unset;
    padding-right: unset;
  }
`;

export const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(3, 260px);
  grid-template-rows: repeat(3, 230px);
  grid-gap: 20px;
  justify-content: center;
  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 260px);
    grid-template-rows: repeat(4, 230px);
  }
  @media screen and (max-width: 700px) {
    margin-left: 40px;
    margin-right: 40px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 230px);
  }
`;

const services = [
  {
    icon: <Icon1 />,
    title: "Prawo Karne",
    href: "/uslugi#prawo-karne",
  },
  {
    icon: <Icon2 />,
    title: "Prawo Cywilne",
    href: "/uslugi#prawo-cywilne",
  },
  {
    icon: <Icon3 />,
    title: "Prawo Rodzinne",
    href: "/uslugi#prawo-rodzinne",
  },
  {
    icon: <Icon4 />,
    title: "Prawo Administracyjne",
    href: "/uslugi#prawo-administracyjne",
  },
  {
    icon: <Icon5 />,
    title: "Obsługa prawna przedsiębiorców",
    href: "/uslugi#obsluga-prawna-przedsiebiorcow",
  },
  {
    icon: <Icon6 />,
    title: "Stała obsługa prawna",
    href: "/uslugi#stala-obsluga-prawna",
  },
  {
    icon: <Icon7 />,
    title: "Mediacje",
    href: "/uslugi#mediacje",
  }
];

const ServicesScope = () => (
  <BackgroundPic id="uslugi">
    <Scope>
      Kancelaria Adwokacka i Mediacyjna Adwokat Adrianny Firlej-Konieckiej świadczy kompleksowe usługi
      prawne we wszystkich dziedzinach prawa głównych specjalizacji, w
      szczególności:
    </Scope>
    <Wrapper>
      {services.map((service) => (
        <ServicesCard {...service} key={service.href} />
      ))}
    </Wrapper>
  </BackgroundPic>
);

export default ServicesScope;
