import React from "react";
import styled from "styled-components";

const StyledBackground = styled.div`
  width: 90%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  p {
    text-align: center;
    color: ${({ theme }) => theme.colors.gold};
    font-size: ${({ theme }) => theme.fontSize.xl};
    font-family: "Parisienne";
    margin: 40px 0;
  }
`;

const Quote = () => (
  <StyledBackground>
    <p>Praca adwokata jest dla mnie pasją, a pomoc klientom priorytetem.</p>
  </StyledBackground>
);

export default Quote;
