import styled from "styled-components";

const Button = styled.button`
  border: none;
  padding: 0.4rem 1.5rem;
  align-self: flex-start;
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.white};
  transition: background-color 0.2s;
  font-family: inherit;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.grey};
  }
`;

export default Button;
